import '../scss/beg-regionalkonferenzen.scss';
import '../../common/js/beg-common';

class BegRegionalkonferenzen {
    static init() {

    }
}

document.addEventListener('DOMContentLoaded', BegRegionalkonferenzen.init, true);
